:root {
    --primary_color: #001AFF;
    --secondary_color: #82bafd;
}

.video {
    padding-top: 125px;
    background: linear-gradient(var(--secondary_color), #FFFFFF, #FFFFFF, #FFFFFF);
}

.video .video_grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: 0 0 calc(25%);
    margin-top: 32px;
    justify-content: center;
}

.video .video_grid .video_card {
    width: 320px;
    height: 520px;
    margin: 12px;
    transition: 1s ease-in-out;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 4px 4px 15px 2px var(--secondary_color), -4px -4px 15px 2px #00000013;
    text-decoration: none;
}

.video .video_grid .video_card:hover {
    transform: scale(1.05);
    transition: 1s ease-in-out;
}

.video .video_grid .video_card img {
    min-width: 100%;
    height: 50%;
}

.video .video_grid .video_card h3 {
    width: 100%;
    color: var(--primary_color);
    font-size: 16px;
    font-weight: 800;
    text-align: start;
    padding: 12px;
    padding-top: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.video .video_grid .video_card p {
    width: 100%;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    text-align: justify;
    padding: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 8;
    -webkit-line-clamp: 8;
    overflow: hidden;
    margin-bottom: 2px;
}