:root {
    --primary_color: #001AFF;
    --secondary_color: #82bafd;
}

.products {
    padding-top: 125px;
    background: linear-gradient(var(--secondary_color), #FFFFFF, #FFFFFF, #FFFFFF);
}

.products .products_banner {
    height: 333.33px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px;
    margin-bottom: 60px;
}

.products .products_banner .products_banner_text {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: first baseline;
}

.products .products_banner .products_banner_text h5 {
    font-size: 13.33px;
    color: #000000;
    font-weight: bold;
}

.products .products_banner .products_banner_text h2 {
    font-size: 32px;
    color: #000000;
    font-weight: bold;
}

.products .products_banner .products_banner_text h4 {
    font-size: 26.66px;
    color: #000000;
    font-weight: 400;
    text-decoration: line-through;
}

.products .products_banner .products_banner_text h1 {
    font-size: 64px;
    color: #000000;
    font-weight: 900;
}

.products .products_banner .products_banner_image {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.products .products_banner .products_banner_image img {
    width: 70%;
    height: 70%;
    object-fit: cover;
    border-radius: 12px;
}

.products .products_tile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: 0 0 calc(50%);
    margin-top: 32px;
}

.products .products_tile .products_card {
    max-width: 480px;
    min-width: 480px;
    height: 572px;
    margin-bottom: 32px;
    margin-top: 32px;
    transition: 1s ease-in-out;
}

.products .products_tile .products_card a {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #FFFFFF;
    border-radius: 24px;
    box-shadow: 4px 4px 64.9px 11px var(--secondary_color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    text-decoration: none;
    padding: 24px;
}

.products .products_tile .products_card:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.products .products_tile .products_card a img {
    width: 100%;
    height: 272px;
    object-fit: cover;
    border-radius: 12px;
    justify-self: center;
}

.products .products_tile .products_card a h1 {
    text-align: start;
    font-size: 26.66px;
    font-weight: bold;
    color: var(--primary_color);
}

.products .products_tile .products_card a p {
    font-size: 16px;
    color: #000000;font-size: 16px;
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.products .products_tile .products_card a h2 {
    color: #000000;
    line-height: 24px;
    font-size: 21.33px;
    font-weight: 900;
    text-align: start;
}

.products .products_tile .products_card a h2 span {
    font-size: 12.66px;
    font-weight: 500;
    text-decoration: line-through;
}

@media only screen and (max-width: 975px) {
    .products .products_banner {
        height: auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: start;
        align-items: first baseline;
        padding: 24px;
    }

    .products .products_banner .products_banner_text {
        width: 100%;
        margin-top: 24px;
    }

    .products .products_banner .products_banner_text h5 {
        font-size: 10.33px;
    }

    .products .products_banner .products_banner_text h2 {
        font-size: 28px;
    }

    .products .products_banner .products_banner_text h4 {
        font-size: 23.33px;
    }

    .products .products_banner .products_banner_text h1 {
        font-size: 45px;
    }

    .products .products_banner .products_banner_image {
        width: 100%;
        height: 250px;
    }

    .products .products_banner .products_banner_image img {
        width: 100%;
        height: 100%;
    }

    .products .products_tile .products_card {
        max-width: 100%;
        min-width: 100%;
        height: 475px;
    }

    .products .products_tile .products_card a img {
        height: 175px;
    }
}