:root {
    --primary_color: #001AFF;
    --secondary_color: #82bafd;
}

.home {
    padding-top: 125px;
    background: linear-gradient(var(--secondary_color), #FFFFFF, #FFFFFF, #FFFFFF);
}

.home_banner {
    width: 100%;
    height: 100%;
    padding: 100px 0px 100px 0px;
}

.home_banner .home_banner_tile {
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    justify-content: center;
    align-items: center;
}

.home_banner .home_banner_text_area {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #000000;
}

.home_banner .home_banner_text_area .home_banner_text_1 {
    width: 100%;
    text-align: start;
    font-size: 21.33px;
    font-weight: 500;
    margin-top: 12px;
}

.home_banner .home_banner_text_area .home_banner_text_2 {
    width: 100%;
    text-align: start;
    font-size: 42.66px;
    font-weight: 900;
}

.home_banner .home_banner_text_area .home_banner_text_3 {
    width: 100%;
    text-align: start;
    font-size: 21.33px;
    font-weight: bold;
    margin-bottom: 45px;
}

.home_banner .home_banner_text_area .home_banner_button {
    width: 150px;
    background-color: var(--primary_color);
    margin-bottom: 10px;
    border: 2px solid transparent;
    transition: .5s ease-in-out;
}

.home_banner .home_banner_text_area .home_banner_button a {
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    text-decoration: none;
    transition: .5s ease-in-out;
}

.home_banner .home_banner_text_area .home_banner_button:hover {
    background-color: transparent;
    color: var(--primary_color);
    border: 2px solid var(--primary_color);
    border-radius: 5px;
}

.home_banner .home_banner_text_area .home_banner_button:hover a {
    color: var(--primary_color);
}

.home_banner .home_banner_text_area .home_banner_social_icons {
    width: 150px;
    display: flex;
    flex-direction: row;
    flex-grow: 4;
    justify-content: space-between;
    padding-right: 12px;
    color: #000000;
}

.home_banner .home_banner_text_area .home_banner_social_icons a {
    cursor: pointer;
    color: #000000;
    text-decoration: none;
}

.home_banner .home_banner_text_area .home_banner_social_icons a:hover {
    cursor: pointer;
    color: var(--primary_color);
}

.home_banner .home_banner_text_area .home_banner_social_icons a:hover {
    cursor: pointer;
    color: var(--primary_color);
}

.home_banner .home_banner_image_area {
    width: 50%;
    padding: 15px;
    display: block;
    align-items: center;
    justify-content: center;
}

.home_banner .home_banner_image_area img {
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: contain;
}

.home_about {
    justify-content: center;
    align-items: center;
}

.home_about h2 {
    color: #000000;
    font-size: 21.33px;
    font-weight: bold;
    margin-bottom: 12px;
}

.home_about h2 span {
    font-size: 24px;
    color: var(--primary_color);
    font-weight: 900;
}

.home_about .home_about_tile {
    display: flex;
    flex-direction: row;
}

.home_about .home_about_tile .home_about_about_area {
    display: block;
    padding: 40px 40px 40px 24px;
}

.home_about .home_about_tile .home_about_about_area p {
    color: #000000;
    font-size: 16px;
    line-height: 200%;
    letter-spacing: 10%;
    text-align: justify;
    padding: 40px;
}

.home_about .home_about_tile .home_about_meta_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home_about .home_about_tile .home_about_meta_area .home_about_meta_card {
    width: 318.33px;
    height: 106px;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border: 3px solid var(--primary_color);
    border-radius: 10px;
    margin: 24px;
}

.home_about .home_about_tile .home_about_meta_area .home_about_meta_card span {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 57.9px -11px #00000076;
    transform: translateY(-30px) translateX(-180px);
}

.home_about .home_about_tile .home_about_meta_area .home_about_meta_card span svg {
    width: 19.33px;
    height: 19.33px;
    color: var(--primary_color);
    margin-top: 20px;
}

.home_about .home_about_tile .home_about_meta_area .home_about_meta_card div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home_about .home_about_tile .home_about_meta_area .home_about_meta_card h3 {
    color: #000;
    font-size: 16px;
    font-weight: bold;
}

.home_about .home_about_tile .home_about_meta_area .home_about_meta_card h1 {
    color: var(--primary_color);
    font-size: 16px;
    font-weight: 900;
}

.home_about .home_about_count_area svg {
    width: 37.33px;
    height: 37.33px;
    color: var(--primary_color);
    padding: 12px;
}

.home_about .home_about_count_area h4 {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
}

.home_testinomials {
    margin-top: 120px;
}

.home_testinomials h2 {
    color: #000000;
    font-size: 21.33px;
    font-weight: bold;
}

.home_testinomials h2 span {
    font-size: 24px;
    color: var(--primary_color);
    font-weight: 900;
}

.home_testinomials .home_testinomials_card {
    user-select: none;
    height: 394.66px;
    display: block;
    background: #FFFFFF;
    padding: 18px;
    border-radius: 12px;
    text-decoration: none;
    box-shadow: 4px 4px 14.9px 16px var(--secondary_color);
    margin: 120px;
    overflow: hidden;
    transition: .3s ease-in;
}

.home_testinomials .home_testinomials_card:hover .home_testinomials_card_hover {
    display: flex;
    visibility: visible;
}

.home_testinomials .home_testinomials_card:hover .home_testinomials_card_main {
    display: none;
}

.home_testinomials .home_testinomials_card:hover {
    background-color: var(--primary_color);
}

.home_testinomials .home_testinomials_card .home_testinomials_card_main {
    width: 100%;
    height: 100%;
    transition: 1s ease-in-out;
}

.home_testinomials .home_testinomials_card .home_testinomials_card_main img {
    width: 170px;
    height: 212.66px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 12px;
}

.home_testinomials .home_testinomials_card .home_testinomials_card_main .home_testinomials_card_name {
    color: var(--primary_color);
    font-size: 16px;
    font-weight: 900;
    padding: 6px;
}

.home_testinomials .home_testinomials_card .home_testinomials_card_main .home_testinomials_card_designation {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    padding: 6px;
}

.home_testinomials .home_testinomials_card .home_testinomials_card_main .home_testinomials_card_company {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    padding: 6px;
}

.home_testinomials .home_testinomials_card .home_testinomials_card_hover {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
    visibility: hidden;
    transition: 1s ease-in-out;
}

.home_testinomials .home_testinomials_card .home_testinomials_card_hover p {
    width: 80%;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.home_testinomials .home_testinomials_card .home_testinomials_card_hover svg {
    color: #FFFFFF;
    margin: 4px;
}

.home_testinomials .home_testinomials_card .home_testinomials_card_hover svg:hover {
    cursor: pointer;
}

.home_courses_products {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px;
    background-color: var(--secondary_color);
    margin-bottom: 24px;
}

.home_courses_products .home_courses_products_tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home_courses_products .home_courses_products_tile h3 {
    font-size: 21.33px;
    font-weight: bold;
    color: #000000;
}

.home_courses_products .home_courses_products_tile .home_courses_products_card_area {
    height: 477.32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.home_courses_products .home_courses_products_tile .home_courses_products_card_area .home_courses_products_card {
    width: 533.33px;
    height: 226.66px;
    display: flex;
    flex-direction: row;
    padding: 12px;
    margin: 12px;
    border-radius: 24px;
    background: #FFFFFF;
    box-shadow: 0 0 56.6px 11px #b1b1b137;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.home_courses_products .home_courses_products_tile .home_courses_products_card_area .home_courses_products_card img {
    border-radius: 12px;
    object-fit: fill;
    width: 228.66px;
    height: 200.66px;
}

.home_courses_products .home_courses_products_tile .home_courses_products_card_area .home_courses_products_card .home_courses_products_card_text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: flex-start;
    padding: 0px 0px 0px 14px;
}

.home_courses_products .home_courses_products_tile .home_courses_products_card_area .home_courses_products_card .home_courses_products_card_text h3 {
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
}

.home_courses_products .home_courses_products_tile .home_courses_products_card_area .home_courses_products_card .home_courses_products_card_text p {
    font-size: 16px;
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    overflow: hidden;
    padding: 1px;
}

.home_courses_products .home_courses_products_tile .home_courses_products_card_area .home_courses_products_card .home_courses_products_card_text a {
    font-size: 16px;
    font-weight: bold;
    color: var(--primary_color);
    text-decoration: none;
}

.home_courses_products .home_courses_products_tile .home_courses_products_button {
    text-decoration: none;
    color: #FFFFFF;
    background-color: var(--primary_color);
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    margin: 20px;
}

@media only screen and (max-width: 1400px) {
    .home_testinomials .home_testinomials_card {
        margin: 66.66px;
    }
}

@media only screen and (max-width: 1200px) {

    .home_courses_products {
        flex-direction: column;
    }

    .home_courses_products .home_courses_products_tile .home_courses_products_card_area {
        height: auto;
    }

    .home_courses_products .home_courses_products_tile .home_courses_products_card_area .home_courses_products_card {
        width: 100%;
        height: auto;
        flex-direction: column;
        overflow: hidden;
    }

    .home_courses_products .home_courses_products_tile .home_courses_products_card_area .home_courses_products_card img {
        width: 100%;
        height: auto;
        margin-bottom: 14px;
    }
}

@media only screen and (max-width: 975px) {
    .home_banner .home_banner_tile {
        flex-direction: column-reverse;
    }

    .home_banner .home_banner_text_area {
        width: 100%;
    }

    .home_banner .home_banner_text_area .home_banner_text_1 {
        font-size: 12px;
    }

    .home_banner .home_banner_text_area .home_banner_text_2 {
        font-size: 20px;
    }

    .home_banner .home_banner_text_area .home_banner_text_3 {
        font-size: 12px;
        margin-bottom: 25px;
    }

    .home_banner .home_banner_text_area .home_banner_button {
        width: 93.33px;
        font-size: 10px;
    }

    .home_banner .home_banner_text_area .home_banner_social_icons {
        width: 93.33px;
    }

    .home_banner .home_banner_image_area {
        width: 100%;
    }

    .home_banner .home_banner_image_area img {
        width: 100%;
        height: 100%;
    }

    .home_about h2 {
        font-size: 12px;
    }

    .home_about h2 span {
        font-size: 15.33px;
    }

    .home_about .home_about_tile {
        display: flex;
        flex-direction: column;
    }

    .home_about .home_about_tile .home_about_about_area {
        display: block;
        padding: 40px;
    }

    .home_testinomials .home_testinomials_card {
        margin: 66.66px;
    }
}

@media only screen and (max-width: 500px) {

    .home_banner .home_banner_text_area .home_banner_text_1 {
        font-size: 8px;
    }

    .home_banner .home_banner_text_area .home_banner_text_2 {
        font-size: 16px;
    }

    .home_banner .home_banner_text_area .home_banner_text_3 {
        font-size: 8px;
    }

    .home_banner .home_banner_text_area .home_banner_button {
        width: 93.33px;
        font-size: 8px;
    }

    .home_about .home_about_tile .home_about_meta_area .home_about_meta_card {
        width: 80%;
        height: 106px;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border: 3px solid var(--primary_color);
        border-radius: 10px;
        margin: 24px;
    }

    .home_about .home_about_tile .home_about_meta_area .home_about_meta_card span {
        width: 40px;
        height: 40px;
        box-shadow: 0px 0px 27.9px -8px #00000076;
        transform: translateY(-20px) translateX(-140px);
    }

    .home_about .home_about_tile .home_about_meta_area .home_about_meta_card span svg {
        width: 17.33px;
        height: 17.33px;
        margin-top: 12px;
    }

    .home_about .home_about_tile .home_about_about_area p {
        padding: 0px;
    }

    .home_testinomials .home_testinomials_card {
        margin: 16.66px;
    }
}