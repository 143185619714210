:root {
    --primary_color: #001AFF;
    --secondary_color: #82bafd;
}

.coursesdetail .coursesdetail_banner {
    height: 407.33px;
}

.coursesdetail .coursesdetail_banner .coursesdetail_banner_overlay {
    width: 100%;
    height: 100%;
    background: linear-gradient(#000000d7, #0000007a);
    display: flex;
    flex-direction: column;
}

.coursesdetail .coursesdetail_banner .coursesdetail_banner_overlay .coursesdetail_brand {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 24px;
}

.coursesdetail .coursesdetail_banner .coursesdetail_banner_overlay img {
    width: 100px;
    height: 100px;
}

.coursesdetail .coursesdetail_banner .coursesdetail_banner_overlay .coursesdetail_course h1 {
    font-size: 64px;
    color: #FFFFFF;
    font-weight: 900;
    margin-bottom: 24px;
}

.coursesdetail .coursesdetail_banner .coursesdetail_banner_overlay .coursesdetail_course p {
    width: 70%;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.coursesdetail .coursesdetail_banner .coursesdetail_banner_overlay .coursesdetail_course {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.coursesdetail .coursesdetail_course_tile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 60px;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_info {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: first baseline;
    justify-self: flex-start;
    align-self: flex-start;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_info h4 {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    margin-top: 24px;
    margin-bottom: 24px;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_info p {
    width: 100%;
    text-align: start;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_info .fee {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_info h2 {
    font-size: 32px;
    font-weight: bold;
    color: #FFFFFF;
    background: var(--primary_color);
    padding: 10px 30px;
    border-radius: 12px;
    margin-right: 32px;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_info span {
    font-size: 24px;
    color: #000000;
    font-weight: 300;
    text-decoration: line-through;
}

.coursesdetail .note {
    margin-top: 45px;
    font-weight: 500;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_form {
    width: 50%;
    align-self: first baseline;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_form form {
    width: 60%;
    padding: 30px;
    border-radius: 25px;
    background: var(--primary_color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_form form h3 {
    font-size: 32px;
    font-weight: bold;
    color: #FFFFFF;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_form form div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: first baseline;
    margin-top: 24px;
    margin-bottom: 24px;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_form form div label {
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 6px;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_form form div input {
    width: 100%;
    height: 54px;
    background: #FFFFFF;
    color: #000000;
    border: none;
    outline: none; 
    border-radius: 5px;
    box-shadow: 0 0 34px 11px #00000020;
    padding-left: 12px;
    padding-right: 12px;
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_form form div  input[type="text"]:focus { 
    outline: none; 
}

.coursesdetail .coursesdetail_course_tile .coursesdetail_course_form form .form_button {
    padding: 12px 80px;
    background: #FFFFFF;
    color: var(--primary_color);
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    border: none;
    outline: none;
    border-radius: 100px;
    margin-top: 32px;
}

@media only screen and (max-width: 975px) {
    .coursesdetail .coursesdetail_course_tile {
        flex-direction: column;
    }
    
    .coursesdetail .coursesdetail_course_tile .coursesdetail_course_info {
        width: 100%;
    }


    .coursesdetail .coursesdetail_course_tile .coursesdetail_course_info .fee {
        flex-direction: column-reverse;
        align-items: first baseline;
    }
    
    .coursesdetail .coursesdetail_course_tile .coursesdetail_course_form {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    
    .coursesdetail .coursesdetail_course_tile .coursesdetail_course_form form {
        width: 100%;
    }

    .coursesdetail .coursesdetail_course_tile .coursesdetail_course_form form .form_button {
        padding: 12px 40px;
    }
}