:root {
    --primary_color: #001AFF;
    --secondary_color: #82bafd;
}

.gallery {
    padding-top: 125px;
    background: linear-gradient(var(--secondary_color), #FFFFFF, #FFFFFF, #FFFFFF);
}

.gallery .gallery_grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: 0 0 calc(25%);
    margin-top: 32px;
    justify-content: center;
}

.gallery .gallery_grid .gallery_card {
    width: 320px;
    height: 320px;
    margin: 12px;
    transition: 1s ease-in-out;
    border-radius: 12px;
    box-shadow: 4px 4px 15px 2px var(--secondary_color), -4px -4px 15px 2px #00000013;
}

.gallery .gallery_grid .gallery_card_hover {
    height: 320px;
    transition: 1s ease-in-out;
    border-radius: 12px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary_color);
    padding: 12px;
}

.gallery .gallery_grid .gallery_card:hover {
    transform: scale(1.1);
}

.gallery .gallery_grid .gallery_card:hover img {
    transition: 1s ease-in-out;
    display: none;
}

.gallery .gallery_grid .gallery_card:hover .gallery_card_hover {
    transition: 1s ease-in-out;
    display: flex;
}

.gallery .gallery_grid .gallery_card img {
    width: 100%;
    height: 100%;
    object-fit: inherit;
    border-radius: 12px;
}

.gallery .gallery_grid .gallery_card .gallery_card_hover h3 {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    margin-top: 6px;
}

.gallery .gallery_grid .gallery_card .gallery_card_hover p {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 16;
    -webkit-line-clamp: 16;
    overflow: hidden;
}

.gallery .popup_outer_container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000b3;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: none;
}

.gallery .popup_outer_container small {
    width: 100%;
    height: 60px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.gallery .popup_outer_container small svg {
    color: #FFFFFF;
    font-size: 30px;
    margin: 6px;
}

.gallery .popup_outer_container small svg:hover {
    cursor: pointer;
}

.gallery .popup_outer_container img {
    width: 90%;
    height: 90%;
    object-fit: contain;
    border-radius: 12px;
    margin-bottom: 12px;
}

.gallery .popup_outer_container h3 {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 700;
    margin-top: 12px;
    padding: 12px;
}

.gallery .popup_outer_container p {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    padding: 12px
}