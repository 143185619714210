:root {
    --primary_color: #001AFF;
    --secondary_color: #82bafd;
}

.footer {
    border-radius: 25px;
    background-color: var(--primary_color);
    margin: 24px;
    padding: 12px;
    padding-top: 24px;
}

.footer .footer_credits_tile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer .footer_credits_tile span {
    font-size: 16px;
    color: #FFFFFF;
}

.footer .footer_main_tile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}

.footer .footer_main_tile .footer_map {
    width: 33.33%;
}

.footer .footer_main_tile .footer_map iframe {
    width: 100%;
    height: 173.33px;
}

.footer .footer_main_tile .footer_management {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: first baseline;
    align-self: self-start;
}

.footer .footer_main_tile .footer_management .footer_management_tile {
    display: flex;
    flex-direction: column;
    align-items: first baseline;
}

.footer .footer_main_tile h4 {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: bold;
    margin-bottom: 12px;
}

.footer .footer_main_tile p {
    font-size: 16px;
    color: #FFFFFF;
}

.footer .footer_main_tile .footer_contact .footer_address {
    width: 100%;
    font-size: 16px;
    color: #FFFFFF;
    text-align: start;
}

.footer .footer_main_tile .footer_contact {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: first baseline;
    justify-content: baseline;
    align-self: self-start;
}

@media only screen and (max-width: 1300px) {
    .footer .footer_main_tile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    
    .footer .footer_main_tile .footer_management {
        width: 100%;
    }
    
    .footer .footer_main_tile .footer_contact {
        width: 100%;
    }

    .footer .footer_main_tile .footer_map {
        width: 100%;
    }
}
