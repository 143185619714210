:root {
    --primary_color: #001AFF;
    --secondary_color: #82bafd;
}

.blog {
    padding-top: 125px;
    background: linear-gradient(var(--secondary_color), #FFFFFF, #FFFFFF, #FFFFFF);
}

.blog .blog_grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: 0 0 calc(50%);
    margin-top: 32px;
    justify-content: center;
}

.blog .blog_grid .blog_card {
    width: 520px;
    height: 220px;
    margin: 12px;
    transition: 1s ease-in-out;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 4px 4px 15px 2px var(--secondary_color), -4px -4px 15px 2px #00000013;
    text-decoration: none;
    display: flex;
    flex-direction: row;
}

.blog .blog_grid .blog_card:hover {
    transform: scale(1.05);
    transition: 1s ease-in-out;
}

.blog .blog_grid .blog_card img {
    width: 220px;
    height: 100%;
}

.blog .blog_grid .blog_card .blog_info {
    display: flex;
    flex-direction: column;
}

.blog .blog_grid .blog_card h3 {
    width: 100%;
    color: var(--primary_color);
    font-size: 16px;
    font-weight: 800;
    text-align: start;
    padding: 12px;
    padding-top: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.blog .blog_grid .blog_card p {
    width: 100%;
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    text-align: justify;
    padding: 12px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: 6;
    -webkit-line-clamp: 6;
    overflow: hidden;
    margin-bottom: 2px;
}

@media only screen and (max-width: 500px) {
    .blog .blog_grid .blog_card {
        width: 100%;
        height: 120px;
    }

    .blog .blog_grid .blog_card img {
        width: 120px;
        height: 100%;
    }

    .blog .blog_grid .blog_card .blog_info h3 {
        font-size: 12px;
        padding: 4px;
        padding-top: 8px;
    }

    .blog .blog_grid .blog_card .blog_info p {
        font-size: 10px;
        padding: 4px;
        line-clamp: 5;
        -webkit-line-clamp: 5;
        padding-bottom: 2px;
    }
}