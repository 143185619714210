:root {
    --primary_color: #001AFF;
    --secondary_color: #82bafd;
}

.menubar {
    position: absolute;
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 26.5px 17px #0000000c;
    z-index: 10;
}

.menubar .menu_brand {
    width: 100px;
    height: 100px;
}

.menubar .menu_text {
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
}

.menubar .menu_text.active_text, .menu_text:hover {
    font-weight: 500;
}

.menubar .menu_button {
    background: transparent;
    font-size: 16px;
    font-weight: 300;
    border: 2px solid;
    border-radius: 5px;
    text-align: center;
}

.menubar .active_button, .menu_button:hover {
    font-weight: 500;
    border: 2px solid transparent;
}

:root {
    --hamburger_color: #000;
}

.menubar .menu_hamburger {
    width: 50px;
    height: 50px;
    background-color: transparent;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    visibility: hidden;
}

.menubar .menu_hamburger::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 5px;
    border-radius: 5px;
    background-color: var(--hamburger_color);
    transform: translateY(-5px);
    transition: 0.3s;
}

.menubar .menu_hamburger::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 5px;
    border-radius: 5px;
    background-color: var(--hamburger_color);
    transform: translateY(5px) translateX(-5px);
    transition: 0.3s;
}

.menubar .menu_hamburger.active::before {
    transform: translateY(0px) rotate(45deg);
}

.menubar .menu_hamburger.active::after {
    width: 30px;
    transform: translateY(0px) rotate(-45deg);
}

/*To avoid bootstrap hamburger*/
.navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: var(--bs-navbar-color);
    background-color: transparent;
    border: none;
    transition: var(--bs-navbar-toggler-transition);
}

.navbar-toggler:focus {
    outline: 0px !important;
    outline: none !important;
    border: none;
    box-shadow: none;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("") !important;
    border-color: blue !important;
}

@media only screen and (max-width: 975px)  {
    .menubar .menu_hamburger {
        visibility: visible;
    }
}